import * as React from 'react';
import { ThemeProvider } from '../../../../components/src/components/Layout/ThemeProvider/ThemeProvider';

const Layout: React.FC = ({ children }) => (
  <ThemeProvider
    render={() => children}
  />
);

export default Layout;
